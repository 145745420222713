<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-card v-if="networkIsEmpty" elevation="0">
          <v-card-title class="black white--text mb-4">
            {{ $t('rtb.network.no_networks') }}
          </v-card-title>
          <v-card-text>
            {{ $t('rtb.network.network_desc') }}
          </v-card-text>
          <v-card-actions>
            <router-link :to="{ name: 'RtbCreateNetwork' }">
              <c-btn color="primary" :label="$t('rtb.network.create_network')" />
            </router-link>
          </v-card-actions>
        </v-card>

        <v-card v-else-if="network.latest_approval.status === 'pending'">
          <v-card-title class="secondary mb-4">
            {{ $t('rtb.network.request_pending') }}
          </v-card-title>
          <v-card-text>
            {{ $t('rtb.network.our_manager') }}
            <br />
            You can contact our
            <live-support-string />
            for the further details.
          </v-card-text>
          <v-card-actions>
            <c-btn color="primary" :label="'Live support'" @click="$intercom.show()" />
          </v-card-actions>
        </v-card>

        <v-card v-else-if="network.latest_approval.status === 'rejected'">
          <v-card-title class="secondary mb-4">
            {{ $t('rtb.network.request_declined') }}
          </v-card-title>
          <v-card-text>
            You can contact our
            <live-support-string />
            for the further details.
          </v-card-text>
          <v-card-actions>
            <c-btn color="primary" :label="'Live support'" @click="$intercom.show()" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import LiveSupportString from '@/components/LiveSupportString.vue'

  export default {
    name: 'RtbNetwork',
    components: {
      LiveSupportString,
      CBtn
    },
    computed: {
      networkIsEmpty() {
        return Object.keys(this.network).length === 0
      },
      ...mapState('rtb', ['network'])
    }
  }
</script>
